import { useHttp } from "@/hooks/useFetch";
import { useUserStore } from "@/store/user";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Question = () => {
  const userStore = useUserStore();
  const navgate = useNavigate();
  return userStore.aiWords.length ? (
    <div
      onClick={() => {
        navgate("/question");
      }}
      className="w-16 cursor-pointer h-16 bg-blue-gray-900 rounded-xl mb-5 flex flex-col items-center justify-center shadow-md"
    >
      <div className="text-white text-md font-bold">AI出题</div>
      <div className="text-white text-md">{userStore.aiWords.length}</div>
    </div>
  ) : (
    <div></div>
  );
};
