export default function Footer() {
  return (
    <div className="h-16 text-sm flex justify-center items-center dark:text-white dark:bg-gray-900">
      <a
        target="_blabk"
        href="https://beian.miit.gov.cn"
        className="text-blue-gray-300"
      >
        晋ICP备2024042419号-1
      </a>
    </div>
  );
}
