import axios from "axios";
import { useGlobalData } from "@/components/Layout/GlobalContext";
import { useEffect, useState } from "react";
import store from "@/utils/store";
import FetchStream from "@/utils/fetchSSE";
import { useParams } from "react-router-dom";

const { DEV, VITE_APP_BASE_URL, VITE_OPEN_PROXY } = import.meta.env;

type FetchParams<T> = {
  url: string;
  method?: "get" | "post";
  params?: object;
  autoFetch?: true;
  onSuccess?: (data: T) => void;
  value?: T | undefined;
};

export function useHttp() {
  const { alert, logout } = useGlobalData();

  const route = useParams();
  const toLogin = () => {
    logout();
    // store.removeItem("token");
    // store.removeItem("userInfo");
    // window.location.reload();
  };

  const api = axios.create({
    baseURL: DEV && VITE_OPEN_PROXY === "true" ? "/dev/" : VITE_APP_BASE_URL,
    timeout: 1000 * 60,
    responseType: "json",
  });


  api.interceptors.request.use((config: any) => {
    /**
     * 全局拦截请求发送前提交的参数
     * 以下代码为示例，在请求头里带上 token 信息
     * 并检查是否有缓存
     */
    const cacheKey = `${config.url}-${JSON.stringify(config.params || config.data)}`;
    const cachedTime = store.getItem(`${cacheKey}-time`) as number;
    const cachedData = store.getItem(cacheKey);
    
    // 如果有缓存，抛出一个特定错误
    if (cachedData && cachedTime > Date.now()) {
      throw {
        isCached: true, // 标记这是一个缓存错误
        data: cachedData, // 返回缓存数据
      };
    } else {
      console.log(cacheKey)
    }
    if (config.headers) {
      config.headers["Authorization"] = store.getItem("token");
      config.headers["AppId"] = route.appId || "man";
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (config.method === "post") {
      // config.data = qs.stringify(config.data, {
      //   arrayFormat: 'brackets',
      // })
    }
    
    return config;
  });

  api.interceptors.response.use(
    (response: any) => {
      /**
       * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
       * 假设返回数据格式为：{ status: 1, error: '', data: '' }
       * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
       * 请求出错时 error 会返回错误信息
       */
      if (response.data.code === 1000) {
        // Cache the response
        const catchTime = response.config.params?.catch || (typeof response.config.data === 'string' ? JSON.parse(response.config.data || '{}').catch : undefined);
        if (catchTime) {
          const getKey = response.config.params ? JSON.stringify(response.config.params) : '';
          const cacheKey = `${response.config.url}-${getKey || (typeof response.config.data === 'string' ? response.config.data : '')}`;
          store.setItem(cacheKey, response.data.data || '');
          store.setItem(`${cacheKey}-time`, Date.now() + catchTime * 60 * 1000); // Save the current time
        }
        
        return Promise.resolve(response.data.data);
      } else {
        if (response.data?.message) {
          alert({
            color: "red",
            message: response.data?.message,
          });
          return Promise.reject(response.data);
        }
        return Promise.reject(response.data);
      }
    },
    (error) => {
      // 捕获缓存错误
      if (error.isCached) {
        return Promise.resolve(error.data); // 返回缓存数据
      }
      // Ensure error.response is defined before accessing its properties
      if (error.response) {
        const { status } = error.response;
        console.log("status", status);
        if (status == 401) {
          alert({
            color: "red",
            message: "登录状态失效，请重新登录",
          });
          toLogin();
          return Promise.reject(error);
        }
        let message = error.message;
        if (message === "Network Error") {
          message = "后端网络故障";
        } else if (message.includes("timeout")) {
          message = "接口请求超时";
        } else if (message.includes("Request failed with status code")) {
          message = `接口${message.substr(message.length - 3)}异常`;
        }
        alert({
          color: "red",
          message,
        });
        return Promise.reject(error);
      } else {
        // Handle the case where error.response is undefined
        console.error("Error response is undefined", error);
        return Promise.reject(error);
      }
    }
  );
  return {
    Http: api,
    FetchStream,
  };
}

export default function useFetch<T = any>(
  { value, url, method = "get", params, onSuccess }: FetchParams<T>,
  watch: any = undefined
) {
  const { Http } = useHttp();

  const [data, setData] = useState<T>(value as T);
  const [loading, setLoading] = useState(false);

  const fetchData = async (
    {
      isPagination,
      param,
    }: {
      isPagination?: boolean;
      param?: any;
    } = {
      param: {},
      isPagination: false,
    }
  ) => {
    setLoading(true);
    const requestParams: any = {
      url: url,
      method: method,
    };
    if (method == "get") {
      requestParams.params = {
        ...params,
        ...param,
      };
    }
    if (method == "post") {
      requestParams.data = {
        ...params,
        ...param,
      };
    }
    const data: T = await Http(requestParams);
    onSuccess && onSuccess(data);
    if (isPagination) {
      setData((prev: any) => {
        return {
          ...prev,
          list: [...prev.list, ...(data as Pagination<T>).list],
        };
      });
    } else {
      setData(data);
    }

    setLoading(false);
    return data;
  };

  if (watch) {
    useEffect(() => {
      fetchData();
    }, []);
  }

  return {
    data,
    setData,
    loading,
    fetchData,
  };
}
