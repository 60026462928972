import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";

const Iconify: React.FC<{
  icon: string;
  size?: number;
  className?: string;
}> = ({ icon, size = 24, className = "" }) => {
  return (
    <div
      className={className}
      style={{
        width: size + "px",
        height: size + "px",
      }}
    >
      <Icon icon={icon} width={size} height={size} />
    </div>
  );
};

export default Iconify;
