import React from "react";
import Iconify from "@/components/common/Iconify";
import { useUserStore } from "@/store/user";

export const ThemeToggle: React.FC<{
  className?: string;
}> = ({ className }) => {
  const isDark = useUserStore((state) => state.isDark);
  const setData = useUserStore((state) => state.setData);

  const toggleTheme = () => {
    const newTheme = !isDark;
    setData("isDark", () => newTheme);

    if (newTheme) {
      document.documentElement.classList.add("dark");
      localStorage.theme = "dark";
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.theme = "light";
    }
  };

  return (
    <button
      onClick={toggleTheme}
      className={`${className} p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors`}
      aria-label="切换主题"
    >
      {isDark ? (
        <Iconify icon="material-symbols:light-mode" className="w-5 h-5" />
      ) : (
        <Iconify icon="material-symbols:dark-mode" className="w-5 h-5" />
      )}
    </button>
  );
};
