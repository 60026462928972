import { useEffect } from "react";
import { GlobalContextProvider } from "./components/Layout/GlobalContext";
import { AppRouter } from "./router";
import { ThemeProvider } from "@material-tailwind/react";

const customTheme = {
  list: {
    styles: {
      base: {
        item: {
          selected: {
            bg: "dark:bg-gray-800 dark:text-gray-300",
          },
          initial: {
            bg: "dark:hover:bg-gray-800",
          },
        },
      },
    },
  },
  input: {
    styles: {
      base: {
        container: {
          className: "!min-w-0",
        },
      },
    },
    variants: {
      outlined: {
        base: {
          input: {
            className: "focus:border-gray-900 dark:focus:border-gray-400",
          },
        },
      },
    },
  },
  dialog: {
    defaultProps: {
      className: "bg-white dark:bg-gray-900",
    },
  },
  card: {
    defaultProps: {
      className: "bg-white dark:bg-gray-900",
    },
  },
  switch: {
    styles: {
      base: {
        input: {
          className: "checked:bg-gray-900 dark:checked:bg-gray-500",
        },
      },
    },
  },
  select: {
    styles: {
      base: {
        option: {
          active: {
            bg: "bg-white dark:bg-gray-800",
          },
          initial: {
            bg: "dark:hover:text-gray-300",
            background: "dark:focus:bg-gray-800 dark:focus:text-gray-300",
          },
        },
      },
    },
  },
  tab: {
    styles: {
      base: {
        indicator: {
          position: "absolute",
          inset: "inset-0",
          zIndex: "z-10",
          height: "h-full",
          bg: "bg-white dark:bg-gray-900 ",
          borderRadius: "rounded-md",
          boxShadow: "shadow",
        },
      },
    },
  },
  drawer: {
    styles: {
      base: {
        overlay: {
          position: "fixed",
        },
      },
    },
  },
};

function App() {
  return (
    <ThemeProvider value={customTheme}>
      <GlobalContextProvider>
        <AppRouter />
      </GlobalContextProvider>
    </ThemeProvider>
  );
}

export default App;
