import LocalStorageUtil from "@/utils/store";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type UserStore = {
    userInfo: UserInfo | null,
    isDark: boolean,
    authCode: string,
    aiWords: string[],
    administrator: string[],
    /**
     * 设置聊天存储状态中的特定键的值。
     * 
     * @param key - 要更新的状态属性的键。
     * @param cb - 一个回调函数，接受键的当前值并返回新值。
     */
    setData: <K extends keyof UserStore>(key: K, cb: (data: UserStore[K]) => UserStore[K]) => void
}

export const useUserStore = create<UserStore>()(
    persist(
        (set) => ({
            userInfo: null,
            aiWords: LocalStorageUtil.getItem('aiWords') || [],
            administrator: [
                '18935962250',
                '13121132123'
            ],
            authCode: (() => {
                return localStorage.authCode || '';
            })(),
            isDark: (() => {
                // 检查 localStorage
                if (typeof localStorage !== 'undefined') {
                    if (localStorage.theme === 'dark') return true;
                    if (localStorage.theme === 'light') return false;
                }
                // 如果没有 localStorage 设置，检查系统偏好
                if (typeof window !== 'undefined' && window.matchMedia) {
                    return window.matchMedia('(prefers-color-scheme: dark)').matches;
                }
                return false; // 默认为亮色主题
            })(),
            setData: <K extends keyof UserStore>(key: K, cb: (data: UserStore[K]) => UserStore[K]) => {
                set((state) => ({
                    [key]: cb(state[key])
                }))
            },
        }),
        {
            name: "chat-store",
        }
    )
);